export default class SearchMatcher {
  constructor(searchQuery) {
    this.searchRegex = new RegExp(SearchMatcher.formatText(searchQuery).split(/[\W]+/).filter(q => q).join('|'), 'i')
  }

  static formatText(value) {
    if (value == null) { return '' }
    return value
      .toLowerCase()
      .replace(/[àáâãäå]/g, 'a')
      .replace(/æ/g, 'ae')
      .replace(/ç/g, 'c')
      .replace(/[èéêë]/g, 'e')
      .replace(/[ìíîï]/g, 'i')
      .replace(/ñ/g, 'n')
      .replace(/[òóôõö]/g, 'o')
      .replace(/œ/g, 'oe')
      .replace(/[ùúûü]/g, 'u')
      .replace(/[ýÿ]/g, 'y')
  }

  match(...args) {
    return this.searchRegex.test(SearchMatcher.formatText(args.join(' ')))
  }
}
